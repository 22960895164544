import React from 'react';
import logo from './logo.svg';
import './App.css';
import KetchwordBasic from './KetchwordBasic';

import { Drawer, FormControl, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent} from '@mui/material';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { CampaignType } from './Props';


function App() {
  const [campaignType, setCampaignType] = React.useState<CampaignType>('rockit-fuel');
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const handleCampaignTypeChange = (evt: SelectChangeEvent<CampaignType>) => {
    setCampaignType(evt.target.value as CampaignType);
  };

  return (
    <div className="App">
      <IconButton onClick={() => setDrawerOpen(true)}><MenuOpenIcon/></IconButton>
      <Drawer anchor={'left'} open={drawerOpen} onClose={() => setDrawerOpen(false)}>
        <FormControl sx={{margin: '8px'}}>
            <InputLabel id="demo-simple-select-label">Campaign Type</InputLabel>
            <Select
                labelId="campaign-type-select-label"
                id="campaign-type-select"
                value={campaignType}
                label="Campaign Type"
                onChange={handleCampaignTypeChange}
                sx={{minWidth: 150}}
            >
              <MenuItem value={"basic"}>Basic</MenuItem>
              <MenuItem value={"rockit-fuel"}>Rock-it Fuel</MenuItem>
            </Select>
        </FormControl>
      </Drawer>
      <KetchwordBasic campaignType={campaignType}/>
    </div>

  );
}

export default App;

import * as React from 'react';
import {DataProps, KetchwordBasicProps} from "./Props";

const KetchwordsBasicPreview: React.FC<DataProps<KetchwordBasicProps>> = props => {

    const transformLineBreaks = (input: string): string => {
        return input.replaceAll("<", "&lt;")
            .replaceAll(">", "&gt;")
            .replaceAll("\n", "<br/>");
    };

    const previewLogo = (file: File): void => {
        const reader = new FileReader();
        const preview = document.querySelector<HTMLImageElement>("img#logo");
        reader.addEventListener("load", () => {
            if (preview){
                preview.src = reader.result ? reader.result.toString() : "";
            }            
        }, false);
        if (file) {
            reader.readAsDataURL(file);
        }
    };

    if (props?.data?.logoFiles?.[0]) {
        previewLogo(props.data.logoFiles[0]);
    }

    return (
        <div className="ketchwords-main">
            <h2>View Your Draft</h2>
            <div className="preview-content">
                <div className="your-logo"><img alt="Company Logo" id="logo" src="https://files.constantcontact.com/bcd01438001/7f7cad7e-478e-4501-8690-3a14174e37bd.png?rdr=true"/></div>
                <div className="thanks-banner">
                <p>Thanks for texting {props.data.ketchword.toLocaleUpperCase()} to 411321<br/>
                    <span className="subline">(1-909-741-1321 via text or WhatsApp)</span>
                </p>
                </div>

                <h2 className="intro-title">ATTACHED IS YOUR REQUEST!</h2>

                <div className="intro" dangerouslySetInnerHTML={{__html: transformLineBreaks(props.data.intro)}}></div>

                <p className="title-prelude"><strong>Enjoy the attached resource:</strong></p>
                <p className="title"><strong><em>&quot;{props.data.title}&quot;</em></strong></p>
                <div className="spacer-thin">&nbsp;</div>
                <h2 className="author-title">ABOUT THE AUTHOR</h2>
                <div className="bio" dangerouslySetInnerHTML={{__html: transformLineBreaks(props.data.bio)}}></div>

                <div className="call-to-action"><a>Click here to connect with {props.data.firstName}!</a></div>

                <div className="footer">
                    <p>
                        <strong>You have texted a Ketchword.</strong><br/>
                        <strong>The educational Ketchword you subscribed to was <span className="ketchword">{props.data.ketchword.toLocaleUpperCase()}</span></strong>
                    </p>
                    <p></p>
                    <p>
                        To stop receiving educational updates from <strong>{props.data.ketchword.toLocaleUpperCase()}</strong> (via text or email)<br/>
                        please text <strong>STOP {props.data.ketchword.toLocaleUpperCase()}</strong> to <strong>411321</strong>.<br/>
                        (or <strong>STOP {props.data.ketchword.toLocaleUpperCase()}</strong> to <strong>1-909-741-411321</strong> via text or WhatsApp)
                    </p>
                </div>
                <div className="ketchwords-logo">
                    <img alt="Ketchwords Logo" src="https://files.constantcontact.com/bcd01438001/17feec14-0648-4821-8278-c9f421f09f34.png?rdr=true"/>
                    <p>Ketch is registered trademarks of Bull's Eye Innovations Corp.</p>
    <p>To learn more about this service for your business, visit <a href="http://ketchwords.com">Ketchwords.com</a></p>
    <p>70 High Street | Suite 211 | Clinton, MA | 01510 | (908) 603-5401</p>
                </div>
            </div>
        </div>
    )
}

export default KetchwordsBasicPreview;
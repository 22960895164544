import {CallToActionKind, DataProps, KetchwordBasicProps, RockitFuelProps} from "./Props";
import {FormEvent} from "react";

export interface AwsPresignedUrlResult {
    url: URL,
    fields: any
}

export interface CampaignSubmission {
    campaign_type: string;
    company_name: string;
    product_name: string;
    given_name: string;
    surname: string;
    email_address: string;
    cta_kind: CallToActionKind;
    cta_target: URL | string;
    intro: string;
    attachment_title: string;
    bio: string;
    logo_url: URL;
    attachment_url: URL,
    trigger_word: string;
    episode_title: string;
}

const KetchwordsBasicUploadIntentUrl = "https://api.ecofiles.info/basic/intent/upload";
const KetchwordsBasicUploadEndpoint = "https://api.ecofiles.info/basic";

/**
 * Removes unsafe characters for S3 keys
 * @param filename
 */
    const encodeFilenameForSafeS3 = (filename: string): string => {
    const UNSAFE_CHARS = /[&$@=;\/:+,? \\{}^%`'">\[\]~<#|]/g
    return filename.replaceAll(UNSAFE_CHARS, "_");
};

/**
 * Configures the target call-to-action URL
 */
const configureCtaUrl = (cta_kind: "email" | "url", cta_target: string | URL) => {
    if (cta_kind === "email") {
        return "mailto:" + cta_target;
    } else {
        return cta_target
    }
};

/** 
 * Generates an appropriate onSubmit event handler for a form to complete
 * @param onSave callback to handle save behavior
 * @param onSaveComplete callback when save has completed
*/
export const campaignSubmissionHandler = (props:DataProps<KetchwordBasicProps | RockitFuelProps>, onSave: (started: boolean) => void, onSaveComplete: (success: boolean) => void) => {


  /**
     * Submits campaign data to API
     * @param attachment_url a S3 URL of a prior uploaded attachment file
     * @param logo_url a S3 URL of a prior uploaded logo file
     */
    const submitCampaign = (attachment_url: string, logo_url: string) => {
        if (attachment_url == null) return;
        let episode_title = (props.data as RockitFuelProps).episodeTitle;
        const submission: CampaignSubmission = {
            campaign_type: props.data.campaignType,
            company_name: props.data.company,
            product_name: props.data.product,
            bio: props.data.bio,
            email_address: props.data.emailAddress,
            cta_kind: props.data.cta.kind,
            cta_target: configureCtaUrl(props.data.cta.kind, props.data.cta.value),
            intro: props.data.intro,
            given_name: props.data.firstName,
            surname: props.data.lastName,
            attachment_title: props.data.title,
            attachment_url: new URL(attachment_url),
            trigger_word: props.data.ketchword,
            logo_url: new URL(logo_url),
            episode_title: episode_title
        };

        fetch(KetchwordsBasicUploadEndpoint, {method: 'POST', headers: {'Content-Type': 'application/json'}, body: JSON.stringify(submission)})
        .then((resp) => resp.json())
        .then((result) => { console.log('Success', result); onSaveComplete(true) })
        .catch((error) => { console.error('Failure', error); onSaveComplete(false) })
    };

    /**
     * Handle posting file data to S3 endpoint as prelude to the submitCampaign function call.
     * @param opts data from prior AWS S3 Presign URL call
     * @return a Promise returning a Fetch API response
     */
    const handleUpload = (opts: AwsPresignedUrlResult, file: File): Promise<Response> => {
        const formData = new FormData();
    
        for(let key in  opts.fields) {
            formData.append(key, opts.fields[key]);
        }
        //const file = props.data.attachmentFiles ? props.data.attachmentFiles[0] : "";
        formData.append("file", file);
        return fetch(opts.url.toString(), {method: 'POST', body: formData})
        .then((result) => { 
            if(result.ok) {
                console.log('Upload sucess', result); return result;
            } else {
                console.error("Unusual response from fetch", result);
                throw Error("Received non-ok from fetch")
            }
        })
    };

    /**
     * Indicates an upload intent to API, which includes expected upload file names
     * The upload intent prepares presign URLs for later use.
     */
    const submitUploadIntent = (filename: string, file: File) => {
        const bodyData = {
            company_name: props.data.company,
            product_name: props.data.product,
            email_address: props.data.emailAddress,
            trigger_word: props.data.ketchword,
            filename: encodeFilenameForSafeS3(filename),
        };
        return fetch(KetchwordsBasicUploadIntentUrl, {method: 'POST', body: JSON.stringify(bodyData)})
          .then((resp) => resp.json())
          .then((result) => handleUpload(result, file));
    }

    /** handleSubmission()...
     *
     * // obtain S3 post URL
     * // submit file data to S3 bucket via post
     * // submit campaign submission
     * 
     * const formData = new FormData();
     * formData.append('File', selectedFileData);
     *
     * fetch( url, { method: 'POST', body: formData })
     *   .then((resp) => resp.json())
     *   .then((result) => console.log('Success', result))
     *   .catch((error) => console.error('Error', error))
     *
     */
    return (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!e.currentTarget.reportValidity()) {
            console.warn("Form is not valid due to errors: ");
            return;
        }

        onSave(true);

        const attachmentFile = props.data.attachmentFiles ? props.data.attachmentFiles[0] : null;
        const logoFile = props.data.logoFiles ? props.data.logoFiles[0] : null;

        var attachmentPromise: Promise<Response> = Promise.resolve(new Response()); 
        var logoPromise: Promise<Response> = Promise.resolve(new Response());

        if(attachmentFile) {
            attachmentPromise = submitUploadIntent(attachmentFile.name, attachmentFile);
        }
        if(logoFile) {
            logoPromise = submitUploadIntent(logoFile.name, logoFile);
        }

        Promise.all<any>([attachmentPromise, logoPromise])
        .then(res => {
            console.log('Uploads complete'); 
            submitCampaign(res[0]?.headers.get("Location"), res[1]?.headers.get("Location"))
        })
        .catch(err => console.error(err));
    };
};